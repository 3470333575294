/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* Basic CSS styling based on provided HTML file */
@import url("https://use.fontawesome.com/releases/v6.1.2/css/all.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100;1,300;1,400;1,500;1,700");
@keyframes fadeIn {
  0% {
    opacity:0
  }
  100% {
    opacity:1
  }
}
body {
  font-family:Roboto, sans-serif;
  font-size:1rem;
  line-height:1.25;
  hyphens:auto
}
main {
  opacity:0;
  animation:fadeIn 750ms ease 150ms;
  animation-fill-mode:forwards
}
p,
ul {
  margin-bottom:0rem
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight:500;
  line-height:1.0;
  margin-bottom:0rem
}
h1 {
  font-size:3.6rem
}
h2 {
  font-size:2.0rem;
  font-weight:300;
  margin-bottom:0.75rem
}
h3 {
  font-size:1.5rem;
  font-weight:400;
  margin-bottom:0.5rem
}
h4 {
  font-size:1.5rem;
  font-weight:300;
  margin-bottom:0.5rem
}
h5 {
  font-size:1.25rem
}
h6 {
  font-size:1.25rem
}
td {
  vertical-align:top;
  padding:0rem 0rem 0.2rem 0rem
}
td:first-child {
  padding-right:2rem
}
td:first-child strong {
  font-size:1.25rem;
  font-weight:500
}
.light {
  color:#1B1B1B;
  background-color:#fff
}
.light mark {
  color:#fff;
  background-color:#1B1B1B;
  padding:2px 4px;
  border-radius:4px
}
.light a {
  color:#1B1B1B;
  text-decoration:none;
  transition:color 200ms
}
.light a:focus,
.light a:hover {
  color:#f66
}
.dark {
  color:#EAEAEA;
  background-color:#1B1B1B
}
.dark mark {
  color:#1B1B1B;
  background-color:#EAEAEA;
  padding:2px 4px;
  border-radius:4px
}
.dark a {
  color:#EAEAEA;
  text-decoration:none;
  transition:color 200ms
}
.dark a:focus,
.dark a:hover {
  color:#f66
}
.content {
  align-items:center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  height:100%;
  width:100%
}
.container {
  max-width:960px;
  margin-bottom:2.5rem
}
.header-container {
  margin-top:2.5rem;
  opacity:0;
  animation:fadeIn 500ms ease;
  animation-fill-mode:forwards
}
.header-left {
  text-align:left
}
.header-right {
  text-align:right
}
.header-right .info {
  font-size:1.2rem;
  line-height:1.2;
  margin:0rem 0.25rem
}
.footer-container {
  text-align:center;
  font-style:italic;
  font-weight:300;
  margin-top:4rem;
  margin-bottom:1rem;
  opacity:0;
  animation:fadeIn 750ms ease 150ms;
  animation-fill-mode:forwards
}
.icons {
  margin:0.25rem 0rem
}
.social i {
  font-size:3.5rem;
  padding:0.25rem;
  transform:scale(0.95, 0.95);
  transition:transform 250ms
}
.social:focus i,
.social:hover i {
  transform:scale(1, 1)
}
.item {
  margin-bottom:1.5rem
}
.item .heading {
  margin-bottom:0.2rem
}
.item .heading .title {
  display:inline
}
.item .heading .fill_1 {
  float:right;
  font-weight:300
}
.item .heading .sub-title {
  display:inline;
  font-weight:300
}
.item .heading .fill_2 {
  float:right;
  font-size:0.75rem;
  font-style:italic;
  font-weight:300
}
.item .description {
  text-align:justify
}
@media screen and (min-width: 768px) {
  h4 {
    writing-mode:vertical-lr;
    transform:rotate(-180deg);
    margin-left:2rem;
    padding-bottom:0.15rem
  }
}
@media screen and (max-width: 767px) {
  .header-container {
    text-align:center
  }
  .header-left {
    text-align:center;
    margin-top:0px
  }
  .header-right {
    text-align:center
  }
  .icons {
    text-align:center
  }
  .heading>br {
    display:none
  }
  .title,
  .sub-title {
    display:block !important
  }
  .fill_1,
  .fill_2 {
    float:none !important
  }
}
@media print {
  * {
    color:#000000 !important;
    background-color:#FFFFFF !important
  }
  .header-left {
    width:58.33333333% !important
  }
  .header-right {
    width:41.66666667% !important
  }
  .header-container {
    margin-top:0rem
  }
  .icons {
    display:none
  }
  .col-md-1 {
    width:8.33333333%
  }
  .col-md-2 {
    width:16.66666667%
  }
  .col-md-3 {
    width:25%
  }
  .col-md-4 {
    width:33.33333333%
  }
  .col-md-5 {
    width:41.66666667%
  }
  .col-md-6 {
    width:50%
  }
  .col-md-7 {
    width:58.33333333%
  }
  .col-md-8 {
    width:66.66666667%
  }
  .col-md-9 {
    width:75%
  }
  .col-md-10 {
    width:83.33333333%
  }
  .col-md-11 {
    width:91.66666667%
  }
  .col-md-12 {
    width:100%
  }
  .col-print-12 {
    width:100%
  }
}
